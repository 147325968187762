export const formUrl = `https://residencesatbiltmore.activehosted.com/proc.php`

/**
 * Contentful field names (plus some aliases, for convenience) mapped to
 * Active Campaign field names.
 */
export const fieldNames = {
  email: "email",
  Email: "email",
  "Event Date": "field[6]",
  message: "field[2]",
  "Message [optional]": "field[2]",
  "Message [required]": "field[2]",
  name: "fullname",
  Name: "fullname",
  phone: "phone",
  "Phone [optional]": "phone",
  "Phone [required]": "phone",
  "Reservation End Date": "field[4]",
  "Reservation Start Date": "field[1]",
  "Spa Services": "field[3]",
}
